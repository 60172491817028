<template>
  <main id="main">
    <!-- hero area start  -->
    <section class="hero-area p-relative st-3">
      <div class="hero-line d-none d-xxl-block"></div>
      <div class="grb__social hero-social d-none d-xxl-block">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/ADEX.Technology.dz"
              target="_blank"
              ><i class="fab fa-facebook-f"></i
            ></a>
          </li>
          <li>
            <a href="https://twitter.com/ADEXTechnology?lang=fr"
              ><i class="fab fa-twitter" target="_blank "></i
            ></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/adex-technology"
              ><i class="fab fa-linkedin" target="_blank "></i
            ></a>
          </li>
        </ul>
      </div>
      <div class="slider-active swiper-container">
        <div class="swiper-wrapper">
          <div
            class="
              single-slider
              slider-height
              swiper-slide
              slider-overlay slider-overlay-3
            "
            data-swiper-autoplay="5000 "
          >
            <div
              class="slide-bg"
              :style="{
                'background-image':
                  'url(' + require('@/assets/img/hero/banner1.jpg') + ')',
              }"
            ></div>
            <div class="p-element d-none d-lg-block">
              <img
                class="h3-s1"
                data-animation="fadeInDown "
                data-delay=".5s "
                src="@/assets/img/shape/h3-s1.png "
                alt="adex intégration"
              />
              <div
                class="h3-s2"
                data-animation="fadeInUp "
                data-delay="1.3s "
              ></div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-9">
                  <div class="hero-content">
                    <p data-animation="fadeInUp " data-delay=".3s ">
                      L'intégration par excellence
                    </p>
                    <h1 data-animation="fadeInUp " data-delay=".5s ">
                      Votre partenaire Technologique !
                    </h1>
                    <div class="hero-content-btn">
                      <router-link
                        :to="{ name: 'About' }"
                        class="grb-btn"
                        data-animation="fadeInUp "
                        data-delay=".9s "
                        >Qui sommes-nous</router-link
                      >
                    </div>
                    <router-link
                      :to="{ name: 'Contact' }"
                      class="grb-border-btn fw-5"
                      data-animation="fadeInUp "
                      data-delay="1.1s "
                    >
                      Contactez-nous
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              single-slider
              slider-height
              swiper-slide
              slider-overlay slider-overlay-3
            "
            data-swiper-autoplay="5000 "
          >
            <div
              class="slide-bg"
              :style="{
                'background-image':
                  'url(' + require('@/assets/img/hero/banner2.jpg') + ')',
              }"
            ></div>
            <div class="p-element d-none d-lg-block">
              <img
                class="h3-s1"
                data-animation="fadeInDown "
                data-delay=".5s "
                src="@/assets/img/shape/h3-s1.png "
                alt="adex intégration"
              />
              <div
                class="h3-s2"
                data-animation="fadeInUp "
                data-delay="1.3s "
              ></div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-9">
                  <div class="hero-content">
                    <p data-animation="fadeInUp " data-delay=".3s ">
                      Cloud Ready
                    </p>
                    <h1 data-animation="fadeInUp " data-delay=".5s ">
                      Architecture accessible partout
                    </h1>
                    <div class="hero-content-btn">
                      <router-link
                        :to="{ name: 'About' }"
                        class="grb-btn"
                        data-animation="fadeInUp "
                        data-delay=".9s "
                        >À Propos</router-link
                      >
                    </div>
                    <router-link
                      :to="{ name: 'Contact' }"
                      class="grb-border-btn fw-5"
                      data-animation="fadeInUp "
                      data-delay="1.1s "
                    >
                      Contactez-nous
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              single-slider
              slider-height
              swiper-slide
              slider-overlay slider-overlay-3
            "
            data-swiper-autoplay="5000 "
          >
            <div
              class="slide-bg"
              :style="{
                'background-image':
                  'url(' + require('@/assets/img/hero/banner3.jpg') + ')',
              }"
            ></div>
            <div class="p-element d-none d-lg-block">
              <img
                class="h3-s1"
                data-animation="fadeInDown "
                data-delay=".5s "
                src="@/assets/img/shape/h3-s1.png "
                alt="adex intégration"
              />
              <div
                class="h3-s2"
                data-animation="fadeInUp "
                data-delay="1.3s "
              ></div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-9">
                  <div class="hero-content">
                    <p data-animation="fadeInUp " data-delay=".3s ">
                      Support et Maintenance
                    </p>
                    <h1 data-animation="fadeInUp " data-delay=".5s ">
                      Service de support sur mesure
                    </h1>
                    <div class="hero-content-btn">
                      <router-link
                        :to="{ name: 'Contact' }"
                        class="grb-btn"
                        data-animation="fadeInUp "
                        data-delay=".9s "
                        >Demander un devis</router-link
                      >
                    </div>
                    <router-link
                      :to="{ name: 'Contact' }"
                      class="grb-border-btn fw-5"
                      data-animation="fadeInUp "
                      data-delay="1.1s "
                    >
                      Contactez-nous
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination slider-swiper-paginations st-3"></div>
      </div>
    </section>
    <!-- hero area end -->
    <!-- about area start  -->
    <section class="about__area st-3">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-xl-6 col-lg-6">
            <div class="about__img mb-30">
              <div class="about__img-inner p-relative st-3">
                <div class="about__img-3-main p-relative">
                  <img
                    src="@/assets/img/about/about3-img.jpg"
                    alt="adex intégration"
                  />
                  <div class="about3-main-shape">
                    <img
                      src="@/assets/img/shape/about3-main-shape.png "
                      alt="adex intégration à propos"
                    />
                  </div>
                </div>
                <div class="about3-p-element">
                  <div class="about-3-s1">
                    <img
                      src="@/assets/img/shape/about-3-s1.png "
                      alt="adex intégration à propos"
                    />
                  </div>
                  <div class="about-3-s2">
                    <img
                      src="@/assets/img/shape/about-3-s2.png "
                      alt="adex intégration à propos"
                    />
                  </div>
                  <div class="about-3-s3">
                    <img
                      src="@/assets/img/shape/about-3-s3.png "
                      alt="adex intégration à propos"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="about__content mb-30">
              <div class="section-title mb-30">
                <div class="border-left st-3">
                  <p>À propos de l'entreprise</p>
                </div>
                <h2>Leader de l'intégration en Algérie</h2>
              </div>
              <p>
                ADEX Intégration est une entreprise d’ingénierie informatique,
                nous accompagnons nos clients en Conseil, Assistance et
                Expertise technique. Nous nous définissons comme un accélérateur
                de performances par la technologie, nous avons pour objectif de
                répondre aux enjeux des systèmes d’information qui se doivent
                d’être disponibles, agiles, sécurisées tout en garantissant la
                performance et la facilité d’utilisation.
              </p>
              <div class="ab-experience st-3">
                <div class="ab-experience-content">
                  <div class="ab-experience-icon st-3">
                    <i class="flaticon-trophy"></i>
                  </div>
                  <div class="ab-experience-text">
                    <p>
                      <span class="st-3">{{ ExpYears }}+</span>ans d'expérience
                    </p>
                  </div>
                </div>
              </div>
              <div class="about__btn">
                <router-link :to="{ name: 'About' }" class="grb-btn st-3"
                  >Qui sommes-nous ?</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- about area end -->
    <!-- service box area  -->
    <section class="service-box-area service-box-area-main pt-40 pb-80">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="section-title mb-55">
              <div class="border-left st-3">
                <p>Nos Services</p>
              </div>
              <h4>
                ADEX Intégration vous propose des services de haute qualité.
              </h4>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'DataCenter' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="fas fa-server"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Data Center</h5>
                    <p>
                      Nous proposons des solutions intégrées qui modernisent
                      votre infrastructure physique tout en optimisant les
                      performances et la disponibilité...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'CyberSecuritySolutions' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="fas fa-shield-alt"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Cyber Security Solutions</h5>
                    <p>
                      Nous vous proposons des solutions simples, complètes et
                      pertinentes pour assurer la sécurité de tous vos supports
                      informatiques...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'SoftwareSolutions' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="fas fa-code"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Software Solutions</h5>
                    <p>
                      Nous vous proposons des solutions software complètes
                      (Messagerie, video conference, ERP, GED, etc)...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'DataManagement' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="flaticon-analytics"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Data Management</h5>
                    <p>
                      Les entreprises génèrent des centaines de milliers de
                      données par jour et ont besoin d’être supporté avec des
                      solutions adéquates. Pour cela, ADEX est votre partenaire
                      de confiance...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'ProfessionalServices' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="flaticon-web-maintenance"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Professional Services</h5>
                    <p>
                      Nous mettons à votre disposition des services de
                      professionnels dans le domaine IT pour l’intégration de
                      vos solutions accompagné de support et maintenance...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'Consulting' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="flaticon-consultation"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Consulting</h5>
                    <p>
                      Riche de notre expertise et expérience, nous avons
                      développé une offre de consulting qui couvre l’assistance
                      de maitrise d’ouvrage, l’accompagnent et le conseil...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- service box end -->
    <!-- choosing area start  -->
    <section class="choosing__area st-3">
      <div class="container">
        <div class="row wow fadeInUp align-items-center">
          <div class="col-xl-6 col-lg-5">
            <div class="choosing__img mb-30 st-3">
              <div class="choosing__img-inner p-relative st-3">
                <div class="choosing__img3-main p-relative">
                  <img
                    src="@/assets/img/about/about4-img.jpg"
                    alt="adex intégration"
                  />
                  <div class="choosing__img-inner-shape">
                    <img
                      src="@/assets/img/shape/choosing-m-shape.png "
                      alt="adex intégration"
                    />
                  </div>
                </div>
                <div class="choosing3-p-element">
                  <div class="choosing-3-s1">
                    <img
                      src="@/assets/img/shape/choosing-s1.png "
                      alt="adex intégration"
                    />
                  </div>
                  <div class="choosing-3-s2">
                    <img
                      src="@/assets/img/shape/choosing-s2.png "
                      alt="adex intégration"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-7">
            <div class="choosing__content mb-30">
              <div class="section-title mb-30">
                <div class="border-left st-3">
                  <p>POURQUOI NOUS CHOISIR</p>
                </div>
                <h2>Nous réalisons vos idées du début à la fin</h2>
              </div>
              <ul class="about-points st-2">
                <li>
                  <div class="points-heading">
                    <div class="p-icon st-3">
                      <i class="flaticon-team"></i>
                    </div>
                    <h5>Partenaire de confiance</h5>
                  </div>
                  <p>
                    Comptant parmi les leaders de l'intégration IT en Algérie,
                    ADEX partage avec ses clients la connaissance de leur marché
                    et la déontologie de leur métier.
                  </p>
                </li>
                <li>
                  <div class="points-heading">
                    <div class="p-icon st-3">
                      <i class="flaticon-creative-team"></i>
                    </div>
                    <h5>Savoir-faire, Maitrise, Expérience.</h5>
                  </div>
                  <p>
                    Avec plus de {{ ExpYears }} ans d'expérience dans le domaine
                    d'intégration informatique, et depuis sa création, ADEX
                    s'est développée pour devenir un partenaire de premier plan.
                  </p>
                </li>
              </ul>
              <div class="about__btn">
                <router-link :to="{ name: 'About' }" class="grb-btn st-3"
                  >Pourquoi choisir ADEX ?</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- choosing area end -->
    <!-- counter area start  -->
    <div class="counter-board-area st-3">
      <div class="container">
        <div class="counter-board-border">
          <div class="row wow fadeInUp counter-board-content st-3">
            <div class="col-lg-3 col-sm-6">
              <div class="counter-board-single mb-40 st-3">
                <i class="fas fa-user-friends"></i>
                <div class="counter-board-number">
                  +<span class="odometer" data-count="200 ">200</span>
                </div>
                <p>CLIENTS SATISFAITS</p>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="counter-board-single mb-40 st-3">
                <i class="fas fa-user"></i>
                <div class="counter-board-number">
                  +<span class="odometer" data-count="60 ">60</span>
                </div>
                <p>EMPLOYÉS QUALIFIÉS</p>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="counter-board-single mb-40 st-3">
                <i class="fas fa-handshake"></i>
                <div class="counter-board-number">
                  +<span class="odometer" data-count="45 ">45</span>
                </div>
                <p>PARTENAIRES TECHNOLOGIQUES</p>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="counter-board-single mb-40 st-3">
                <i class="fas fa-graduation-cap"></i>
                <div class="counter-board-number">
                  +<span class="odometer" data-count="150 ">150</span>
                </div>
                <p>CERTIFICATIONS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- counter area end -->
    <!-- testimonial area start  -->
    <section class="testimonial-area st-3 grey-bg">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-md-8">
            <div class="section-title mb-55">
              <div class="border-left st-3">
                <p>TÉMOIGNAGES</p>
              </div>
              <h2>Ils nous font confiance</h2>
            </div>
          </div>
          <div class="col-md-4">
            <div class="testimonial-nav-3">
              <div class="testimonial-btn-prev">
                <i class="fal fa-angle-left"></i>
              </div>
              <div class="testimonial-btn-next">
                <i class="fal fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="testimonial-wrapper p-relative">
              <div class="swiper-container testimonial-active-3 st-3">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="testimonial-single st-3 mb-50">
                      <ul class="testimonial-review st-3">
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                      </ul>
                      <p>
                        Ce qui nous a encouragé à choisir l’intégrateur ADEX
                        Technology, parmi plusieurs autres soumissionnaires,
                        c’est la vision stratégique de ses managers au projet et
                        leur conviction du challenge affronté. Tout au long de
                        la mise en place de la solution PRA, l’équipe technique
                        d’ADEX Technology a répondu présente et a faitpreuve
                        d’une forte collaboration avec ses compétences locales
                        et étrangères
                      </p>
                      <div class="testimonial-img st-3">
                        <img
                          src="@/assets/img/testimonial/tem-1.jpg "
                          alt="adex intégration témoignage"
                        />
                      </div>
                      <div class="testimonial-name st-3">
                        <h5>M. GUEMMAT SID ALI</h5>
                        <p>
                          Directeur des Systèmes d'Informations, Housing Bank
                        </p>
                      </div>
                      <div class="testimonial-quote st-3">
                        <i class="fal fa-quote-right"></i>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="testimonial-single st-3 mb-50">
                      <ul class="testimonial-review st-3">
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                      </ul>
                      <p>
                        La modernisation de notre système d’information est une
                        étape cruciale dans notre stratégie de développement.
                        Nous avions besoin d’une infrastructure stable, et qui
                        pourrait accompagner notre évolution, permettant à nos
                        équipes d’être productives et sauvegardant l’intégrité
                        de nos données.
                      </p>
                      <div class="testimonial-img st-3">
                        <img
                          src="@/assets/img/testimonial/tem-3.png "
                          alt="adex intégration témoignage"
                        />
                      </div>
                      <div class="testimonial-name st-3">
                        <h5>M.DAMMA SAAD</h5>
                        <p>PDG, Mobilis</p>
                      </div>
                      <div class="testimonial-quote st-3">
                        <i class="fal fa-quote-right"></i>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="testimonial-single st-3 mb-50">
                      <ul class="testimonial-review st-3">
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                        <li><i class="fas fa-star"></i></li>
                      </ul>
                      <p>
                        ADEX Technology, en se distinguant par sa maîtrise des
                        technologies déployées, à savoir BladeCenter IBM, Cisco,
                        VMware, Microsoft, Trend Micro, a été le partenaire
                        adéquat pour la réalisation de ce projet. Son personnel
                        hautement qualifié a réservé toute son attention aux
                        ingénieurs et au management d‘ELIT et a ainsi travaillé
                        en parfaite harmonie avec l‘équipe projet d’ELIT. Ceci a
                        engendré une synchronisation parfaite quant aux
                        activités, aux délais et aux coûts inhérents à la
                        gestion de ce projet. Cette démarche à valeur ajoutée a
                        été le facteur prédominant pour la réussite de projet.
                      </p>
                      <div class="testimonial-img st-3">
                        <img
                          src="@/assets/img/testimonial/tem-2.png "
                          alt="adex intégration témoignage"
                        />
                      </div>
                      <div class="testimonial-name st-3">
                        <h5>M.AGGOUN ALI</h5>
                        <p>Directeur de projet, ELIT</p>
                      </div>
                      <div class="testimonial-quote st-3">
                        <i class="fal fa-quote-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- testimonial area end -->
    <!-- hire area start  -->
    <section
      class="hire-area"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/bg/hire-bg.jpg') + ')',
      }"
    >
      <div class="container">
        <div class="row wow fadeInUp justify-content-center">
          <div class="col-lg-8">
            <div class="hire-content text-center">
              <div class="section-title mb-55">
                <h2 class="white-color">Vous avez des DES PROJETS EN TÊTE ?</h2>
              </div>
              <div class="hire-btn">
                <router-link :to="{ name: 'Contact' }" class="grb-btn st-3"
                  >Contactez-nous dès maintenant</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- hire area end  -->

    <!-- Partenaires area start -->
    <section class="blog-area pt-120 blog-area-3 mb-5">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="section-title mb-55">
              <div class="border-left st-3">
                <p>Partenaires</p>
              </div>
              <h2>Nos Partenaires technologiques</h2>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="col-12">
            <Partners></Partners>
          </div>
        </div>
      </div>
    </section>
    <!-- Partenaires area end -->
  </main>
</template>

<script>
import Partners from "@/components/Partners.vue";
export default {
  name: "Home",
  components: {
    Partners,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  mounted() {
    document.title = "ADEX Intégration - Votre partenaire Technologique !";
  },
  computed: {
    ExpYears: function () {
      return this.currentYear - 2009;
    },
  },
};
</script>
