<template>
  <!-- footer area start  -->
  <footer>
    <section class="footer-area pt-100 pb-60">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-4 col-md-6">
            <div class="footer-widget mb-40">
              <div class="footer-logo st-3 p-relative">
                <div class="footer-shape-st-3">
                  <img
                    src="@/assets/img/shape/foooter-shape-st-3.png "
                    alt="Adex Intégration logo png"
                  />
                </div>
                <router-link :to="{ name: 'Home' }">
                  <img
                    src="@/assets/img/logo/logo-couleurs.png "
                    alt="Adex Intégration logo png"
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="footer-widget mb-40">
              <div class="footer-widget-title">
                <h4>ADEX Intégration</h4>
              </div>
              <ul class="footer-list st-2 st-3">
                <li>
                  <router-link :to="{ name: 'Home' }">Accueil</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'About' }"
                    >Qui sommes-nous ?</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'Services' }">Services</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Portfolio' }"
                    >Portfolio</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'Career' }">Carrière</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="footer-widget mb-40 srv-m">
              <div class="footer-widget-title">
                <h4>Contact</h4>
              </div>
              <ul class="worktime-list">
                <li>
                  <h5>Adresse :</h5>
                  <span
                    >Villa N°09 bis, Cité Ben Haddadi Essaid, Dar Diaf 16002
                    Chéraga,Alger</span
                  >
                </li>

                <li>
                  <h5>Téléphone :</h5>
                  <span>+213 23 27 90 78 / 80</span>
                </li>
                <li>
                  <h5>E-Mail :</h5>
                  <span>contact@adextechnology.dz</span>
                </li>
                <li>
                  <h5>Réseaux sociaux :</h5>
                  <div class="grb__social footer-social offset-social">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/ADEX.Technology.dz"
                          target="_blank"
                          ><i class="fab fa-facebook-f"></i
                        ></a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/ADEXTechnology?lang=fr"
                          target="_blank"
                          ><i class="fab fa-twitter"></i
                        ></a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/adex-technology"
                          target="_blank"
                          ><i class="fab fa-linkedin" target="_blank"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="copyright-area st-2">
      <div class="container">
        <div class="row wow fadeInUp align-items-center text-center">
          <div class="col-lg-12 col-md-12">
            <div class="copyright-text st-2">
              <p>
                ADEX Intégration est une marque commerciale d’<a
                  href="https://www.adextechnology.dz/"
                  class="footer-brand-link"
                  target="_blank"
                  >ADEX Technology</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp align-items-center text-center">
          <div class="col-lg-12 col-md-12">
            <div class="copyright-text st-2">
              <p>
                Développé par
                <a
                  href="https://www.adextechnology.dz/"
                  class="footer-brand-link"
                  target="_blank"
                  >ADEX Technology</a
                >
                | Tous droits réservés
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- footer area end -->
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>