import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/About.vue')
    },
    {
        path: '/career',
        name: 'Career',
        component: () =>
            import ('../views/Career.vue')
    },
    {
        path: '/services/software-solutions',
        name: 'SoftwareSolutions',
        component: () =>
            import ('../views/Services/SoftwareSolutionsService.vue')
    },
    {
        path: '/services/consulting',
        name: 'Consulting',
        component: () =>
            import ('../views/Services/ConsultingService.vue')
    },
    {
        path: '/services/cyber-security',
        name: 'CyberSecuritySolutions',
        component: () =>
            import ('../views/Services/CyberSecurityService.vue')
    },
    {
        path: '/services/data-center',
        name: 'DataCenter',
        component: () =>
            import ('../views/Services/DataCenterService.vue')
    },
    {
        path: '/services/data-management',
        name: 'DataManagement',
        component: () =>
            import ('../views/Services/DataManagementService.vue')
    },
    {
        path: '/services/professional-services',
        name: 'ProfessionalServices',
        component: () =>
            import ('../views/Services/ProfessionalServicesService.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () =>
            import ('../views/Contact.vue')
    },

    {
        path: '/portfolio',
        name: 'Portfolio',
        component: () =>
            import ('../views/Portfolio.vue')
    },
    {
        path: '/services',
        name: 'Services',
        component: () =>
            import ('../views/Services.vue')
    },
    {
        path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
        component: () =>
            import ('../views/Errors/NotFoundComponent.vue')
    }
]

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router