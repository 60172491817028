<template>
  <div>
    <Header></Header>

    <router-view />
    <Footer></Footer>

    <Modal @close="handleClose" :modalActive="modalActive" />
  </div>
</template>
<script>
import Header from "@/components/Template/Header.vue";
import Footer from "@/components/Template/Footer.vue";
import Modal from "@/components/Modal.vue";
export default {
  components: {
    Header,
    Footer,
    Modal
  },
  name: "App",
  data() {
  },

};
</script>
