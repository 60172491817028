import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* Import all css files */
import "@/assets/css/bootstrap.min.css"
import "@/assets/css/swiper-bundle.css"
import "@/assets/css/animate.min.css"
import "@/assets/css/custom-animation.css"
import "@/assets/css/magnific-popup.css"
import "@/assets/css/odometer-theme-default.css"
import "@/assets/css/fontawesome-all.min.css"
import "@/assets/css/meanmenu.css"
import "@/assets/css/flaticon.css"
import "@/assets/css/main.css"


createApp(App).use(store).use(router).mount('#app')