<template>
  <main>
    <!-- page title area start  -->
    <section
      class="page-title-area"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/bg/about-bg.png') + ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-content text-center">
              <div class="page-title-heading">
                <h1>Qui sommes-nous ?</h1>
              </div>
              <nav class="grb-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Accueil</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    À Propos
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- page title area end -->
    <!-- about area start  -->
    <section class="about-details pt-140">
      <div class="container">
        <div
          class="about-details-box mt-30"
          :style="{
            'background-image':
              'url(' + require('@/assets/img/bg/about-details-bg.jpg') + ')',
          }"
        >
          <div class="row wow fadeInUp justify-content-end">
            <div class="col-xl-6 col-md-10">
              <div class="about-details-box-content">
                <h5>
                  Nous accompagnons nos clients en Conseil, Assistance et
                  Expertise Technique.
                </h5>
                <p>
                  ADEX Intégration est une entreprise d’ingénierie informatique,
                  nous accompagnons nos clients en Conseil, Assistance et
                  Expertise Technique. Nous nous définissons comme un
                  accélérateur de performances par la technologie, nous avons
                  pour objectif de répondre aux enjeux des systèmes
                  d’information qui se doivent d’être disponibles, agiles,
                  sécurisées tout en garantissant la performance et la facilité
                  d’utilisation.
                </p>
                <ul class="about-points st-ab">
                  <li>
                    <div class="points-heading">
                      <div class="p-icon">
                        <i class="flaticon-team"></i>
                      </div>
                      <h5>Partenaire de confiance</h5>
                    </div>
                    <p>
                      Comptant parmi les leaders de l'intégration IT en Algérie,
                      ADEX Intégration se dresse comme un partenaire
                      technologique de confiance pour accompagner et conseiller
                      ses clients sur les solutions IT.
                    </p>
                  </li>
                  <li>
                    <div class="points-heading">
                      <div class="p-icon">
                        <i class="flaticon-creative-team"></i>
                      </div>
                      <h5>Savoir-faire, Maitrise, Expérience.</h5>
                    </div>
                    <p>
                      Avec plus de {{ ExpYears }} ans d'expérience dans le
                      domaine de l'intégration informatique, depuis sa création,
                      ADEX Intégration met à la disposition de ses clients son
                      savoir faire et son expertise dans le domaine.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- about area end -->
    <!-- skill area start  -->
    <section class="skill-area pt-110 pb-85">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="about__content mb-30">
              <div class="section-title mb-30">
                <h2>NOUS AVONS DE L'EXPÉRIENCE ET LA COMPÉTENCE</h2>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="ab-experience st-3">
              <div class="ab-experience-content">
                <div class="ab-experience-icon">
                  <i class="flaticon-trophy"></i>
                </div>
                <div class="ab-experience-text">
                  <p>
                    <span>{{ ExpYears }}+</span>Ans expérience
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <p>
              Nos {{ ExpYears }} ans d’expertise aux coté des équipes métiers,
              nous permettent de traduire vos impératifs en solutions de
              services personnalisés avec engagement de résultat. Nous nous
              proposons d’être votre partenaire de confiance pour garantir la
              performance et la sécurisation de votre SI et assurer votre
              transition vers le cloud. ADEX Intégration est l’une des marques
              commerciales du groupe ADEX Technology. Pour plus de détails
              consulter notre
              <a
                class="about-link"
                href="https://www.adextechnology.dz/"
                target="_blank"
              >
                site web</a
              >.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- skill area end -->
    <!-- counter area start  -->
    <div class="counter-board-area st-3">
      <div class="container">
        <div class="counter-board-border">
          <div class="row wow fadeInUp counter-board-content st-3">
            <div class="col-lg-3 col-sm-6">
              <div class="counter-board-single mb-40 st-3">
                <i class="fas fa-user-friends"></i>
                <div class="counter-board-number">
                  +<span class="odometer" data-count="200 ">200</span>
                </div>
                <p>CLIENTS SATISFAITS</p>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="counter-board-single mb-40 st-3">
                <i class="fas fa-user"></i>
                <div class="counter-board-number">
                  +<span class="odometer" data-count="60 ">60</span>
                </div>
                <p>EMPLOYÉS QUALIFIÉS</p>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="counter-board-single mb-40 st-3">
                <i class="fas fa-handshake"></i>
                <div class="counter-board-number">
                  +<span class="odometer" data-count="45 ">45</span>
                </div>
                <p>PARTENAIRES TECHNOLOGIQUES</p>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="counter-board-single mb-40 st-3">
                <i class="fas fa-graduation-cap"></i>
                <div class="counter-board-number">
                  +<span class="odometer" data-count="150 ">150</span>
                </div>
                <p>CERTIFICATIONS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- counter area end -->
    <!-- service box area  -->
    <section class="service-box-area pt-40 pb-80">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="section-title mb-55">
              <div class="border-left st-3">
                <p>Nos valeurs</p>
              </div>
              <h4>
                ADEX Intégration se base sur les valeurs du groupe et qui
                représentent le socle de son offre :
              </h4>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="col-lg-6 col-md-6">
            <div class="service-box-single mb-40">
              <div class="service-box-content st-3">
                <div class="service-box-content-icon">
                  <i class="flaticon-idea"></i>
                </div>
                <div class="service-box-content-text">
                  <h4>
                    <router-link :to="{ name: 'About' }">Innover</router-link>
                  </h4>
                  <p>
                    Aller de l’avant et innover pour avancer et proposer le
                    meilleur à nos clients, grâce à un département R&D qui
                    innove et applique les dernières innovations IT comme le big
                    Data, l’IOT et l'intelligence artificielle.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="service-box-single mb-40">
              <div class="service-box-content st-3">
                <div class="service-box-content-icon">
                  <i class="flaticon-consultation"></i>
                </div>
                <div class="service-box-content-text">
                  <h4>
                    <router-link :to="{ name: 'About' }"
                      >Entreprendre</router-link
                    >
                  </h4>
                  <p>
                    Ce lancer et encourager nos employés à maitriser de
                    nouvelles technologies et de nouvelles solutions afin de les
                    proposer à nos clients en apportant notre valeur ajoutée.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="service-box-single mb-40">
              <div class="service-box-content st-3">
                <div class="service-box-content-icon">
                  <i class="flaticon-analytics"></i>
                </div>
                <div class="service-box-content-text">
                  <h4>
                    <router-link :to="{ name: 'About' }"
                      >Progresser</router-link
                    >
                  </h4>
                  <p>
                    C’est se lancer dans de nouvelles activités comme la
                    formation ou le cloud et garder l’esprit start up pour
                    avancer et regarder vers le futur.Aussi, s'investir dans les
                    technologies et dans la ressource humaine.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="service-box-single mb-40">
              <div class="service-box-content st-3">
                <div class="service-box-content-icon">
                  <i class="flaticon-web-maintenance"></i>
                </div>
                <div class="service-box-content-text">
                  <h4>
                    <router-link :to="{ name: 'About' }">Dynamiser</router-link>
                  </h4>
                  <p>
                    Bouger constamment, suivre le mouvement, innover en donnant
                    un nouveau souffle à l’intérieure de l’entreprise pour qu’il
                    ce reflète à l’extérieure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- service box end -->
    <!-- Partenaires area start -->
    <section class="blog-area pt-40 blog-area-3 mb-5">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="section-title mb-55">
              <div class="border-left st-3">
                <p>Partenaires</p>
              </div>
              <h2>Nos Partenaires technologiques</h2>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="col-12">
            <Partners></Partners>
          </div>
        </div>
      </div>
    </section>
    <!-- Partenaires area end -->
  </main>
</template>
<script>
import Partners from "@/components/Partners.vue";
export default {
  name: "About",
  components: {
    Partners,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  mounted() {
    document.title = "ADEX Intégration - À Propos";
  },
  computed: {
    ExpYears: function () {
      return this.currentYear - 2009;
    },
  },
};
</script>