<template>
  <main>
    <!-- page title area start  -->
    <section
      class="page-title-area"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/bg/contact-background.png') + ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-content text-center">
              <div class="page-title-heading">
                <h1>Contact</h1>
              </div>
              <nav class="grb-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Accueil</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Contact
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- page title area end -->
    <!-- contact area start  -->
    <div class="contact-area pt-145 pb-120">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-4">
            <div class="contact-address">
              <div class="contact-heading">
                <h4>Coordonnées</h4>
              </div>
              <ul class="contact-address-list">
                <li>
                  <div class="contact-list-icon">
                    <i class="fas fa-phone-alt"></i>
                  </div>
                  <div class="contact-list-text">
                    <span
                      ><a href="tel:(213)23279078"
                        >+213 23 27 90 78 / 80</a
                      ></span
                    >
                    <span
                      ><a href="tel:(213)770825096"> +213 770 825 096</a></span
                    >
                  </div>
                </li>
                <li>
                  <div class="contact-list-icon st-3">
                    <i class="fas fa-envelope"></i>
                  </div>
                  <div class="contact-list-text">
                    <span
                      ><a href="mailto:contact@adextechnology.dz"
                        >contact@adextechnology.dz</a
                      ></span
                    >
                  </div>
                </li>
                <li>
                  <div class="contact-list-icon">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="contact-list-text">
                    <span
                      >Villa N°09 bis, Cité Ben Haddadi Essaid, <br />
                      Dar Diaf 16002 Chéraga, Alger</span
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="get-in-touch">
              <div class="contact-heading">
                <h4>CONTACTEZ-NOUS</h4>
              </div>
              <ContactForm></ContactForm>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- contact area end -->
    <!-- map area start  -->
    <div class="contact-map-area">
      <iframe
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=cit%C3%A9%20ben%20haddadi%20es-said,%2016002%20dar%20ediaf,%20cheraga+(ADEX%20Technology)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
    </div>
    <!-- map area end -->
  </main>
</template>
<script>
import ContactForm from "@/components/Forms/ContactForm.vue";
export default {
  name: "Contact",
  components: {
    ContactForm,
  },
  mounted() {
    document.title = "ADEX Intégration - Contact";
  },
};
</script>