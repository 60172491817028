<template>
  <div v-show="modalActive" class="vue-modal">

    <div class="vue-modal__container">
      <img @click="close" class="close-icon" src="../assets/img/close.png" alt="">
      <img class="changeAddressImg" src="../assets/img/changeAddress.jpg" />
    </div>
  </div>

</template>

<script>
export default {
  name: "Modal",
  props: [],
  components: {},
  data() {
    return {
      modalActive: this.open()
    }
  },
  methods: {
    open: function () {
      const timeout = setTimeout(() => {

        var currentDate = new Date();
        var dueDate = new Date("2022-11-05")
        this.modalActive = currentDate < dueDate;
        clearTimeout(timeout)
      }, 2000)

      return false;
    },
    close: function () {
      this.modalActive = false;
    }
  }
};
</script>


<style>
.vue-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.151);

  z-index: 2000;

  display: flex;
  justify-content: center;
  align-items: center;
}

.vue-modal .vue-modal__container {
  width: 90%;
  max-width: 800px;
  border: 1px solid rgba(128, 128, 128, 0.315);
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  animation-name: redChange;
  animation-duration: 1s;
}

.vue-modal .vue-modal__container .changeAddressImg {
  width: 100%;
  height: 100%;
}


.vue-modal .vue-modal__container .close-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  transition: all 0.2s ease-in-out;
}

.vue-modal .vue-modal__container .close-icon:hover {
  transform: scale(1.05);
}

@keyframes redChange {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}
</style>