<template>
  <main>
    <!-- page title area start  -->
    <section
      class="page-title-area"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/bg/counter-board-bg.jpg') + ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-content text-center">
              <div class="page-title-heading">
                <h1>Services</h1>
              </div>
              <nav class="grb-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Accueil</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page ">
                    Services
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- page title area end -->
    <!-- service box area  -->
    <section class="service-box-area service-box-area-main pt-150 pb-80">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'DataCenter' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="fas fa-server"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Data Center</h5>
                    <p>
                      Nous proposons des solutions intégrées qui modernisent
                      votre infrastructure physique tout en optimisant les
                      performances et la disponibilité...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'CyberSecuritySolutions' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="fas fa-shield-alt"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Cyber Security Solutions</h5>
                    <p>
                      Nous vous proposons des solutions simples, complètes et
                      pertinentes pour assurer la sécurité de tous vos supports
                      informatiques...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'SoftwareSolutions' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="fas fa-code"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Software Solutions</h5>
                    <p>
                      Nous vous proposons des solutions software complètes
                      (Messagerie, video conference, ERP, GED, etc)...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'DataManagement' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="flaticon-analytics"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Data Management</h5>
                    <p>
                      Les entreprises génèrent des centaines de milliers de
                      données par jour et ont besoin d’être supporté avec des
                      solutions adéquates. Pour cela, ADEX est votre partenaire
                      de confiance...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'ProfessionalServices' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="flaticon-web-maintenance"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Professional Services</h5>
                    <p>
                      Nous mettons à votre disposition des services de
                      professionnels dans le domaine IT pour l'intégration de
                      vos solutions accompagné de support et maintenance...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-4 col-md-6">
            <router-link :to="{ name: 'Consulting' }">
              <div class="service-box-single mb-40">
                <div class="service-box-content st-1">
                  <div class="service-box-content-icon st-1">
                    <i class="flaticon-consultation"></i>
                  </div>
                  <div class="service-box-content-text">
                    <h5>Consulting</h5>
                    <p>
                      Riche de notre expertise et expérience, nous avons
                      développé une offre de consulting qui couvre l'assistance
                      de maitrise d’ouvrage, l’accompagnent et le conseil...
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- service box end -->
    <!-- partners area start  -->
    <section class="partners-area pb-80">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-6">
            <div class="partners-content mb-40">
              <div class="section-title mb-35">
                <h2>NOS PARTENAIRES MONDIAUX</h2>
              </div>
              <p>
                Depuis le début de l’aventure d'ADEX, nous travaillons avec des
                partenaires internationaux, autant soucieux que nous de réaliser
                nos projets avec un engagement responsable et de qualité.
              </p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="partners-logo pl-100">
              <div class="row">
                <div class="col-sm-6">
                  <div class="single-partner">
                    <a href="# "
                      ><img src="@/assets/img/brand/ibm.png " alt="ibm"
                    /></a>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="single-partner text-end">
                    <a href="# "
                      ><img src="@/assets/img/brand/cisco.png " alt="cisco"
                    /></a>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="single-partner text-center">
                    <a href="# "
                      ><img
                        src="@/assets/img/brand/microsoft.png "
                        alt="microsoft"
                    /></a>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="single-partner">
                    <a href="# "
                      ><img src="@/assets/img/brand/huawei.png " alt="huawei"
                    /></a>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="single-partner text-end">
                    <a href="# "
                      ><img src="@/assets/img/brand/redhat.png " alt="redhat"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- partners area end -->
    <!-- hire area start  -->
    <section
      class="hire-area"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/bg/hire-bg.jpg') + ')',
      }"
    >
      <div class="container">
        <div class="row wow fadeInUp justify-content-center">
          <div class="col-lg-8">
            <div class="hire-content text-center">
              <div class="section-title mb-55">
                <h2 class="white-color">Vous avez DES PROJETS EN TÊTE ?</h2>
              </div>
              <div class="hire-btn">
                <router-link :to="{ name: 'Contact' }" class="grb-btn st-3"
                  >Contactez-nous dès maintenant</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- hire area end  -->
    <!-- testimonial area start  -->
    <div class="testimonial-area st-2">
      <div class="container">
        <div class="row wow fadeInUp align-items-center">
          <div class="col-lg-6">
            <div class="section-title mb-30">
              <div class="border-left">
                <p>TÉMOIGNAGES</p>
              </div>
              <h2>Ils nous font confiance</h2>
            </div>
            <div class="swiper-container testimonial-active-2 st-3">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="testimonial-single">
                    <p class="mb-30">
                      Ce qui nous a encouragé à choisir l’intégrateur ADEX
                      Technology, parmi plusieurs autres soumissionnaires, c’est
                      la vision stratégique de ses managers au projet et leur
                      conviction du challenge affronté. Tout au long de la mise
                      en place de la solution PRA, l’équipe technique d’ADEX
                      Technology a répondu présente et a faitpreuve d’une forte
                      collaboration avec ses compétences locales et étrangères
                    </p>
                    <div class="testimonial-name">
                      <h5>M. GUEMMAT SID ALI</h5>
                      <p>Directeur des Systèmes d'Informations, Housing Bank</p>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="testimonial-single">
                    <p class="mb-30">
                      La modernisation de notre système d’information est une
                      étape cruciale dans notre stratégie de développement. Nous
                      avions besoin d’une infrastructure stable, et qui pourrait
                      accompagner notre évolution, permettant à nos équipes
                      d’être productives et sauvegardant l’intégrité de nos
                      données.
                    </p>
                    <div class="testimonial-name">
                      <h5>M.DAMMA SAAD</h5>
                      <p>PDG, Mobilis</p>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="testimonial-single">
                    <p class="mb-30">
                      ADEX Technology, en se distinguant par sa maîtrise des
                      technologies déployées, à savoir BladeCenter IBM, Cisco,
                      VMware, Microsoft, Trend Micro, a été le partenaire
                      adéquat pour la réalisation de ce projet. Son personnel
                      hautement qualifié a réservé toute son attention aux
                      ingénieurs et au management d‘ELIT et a ainsi travaillé en
                      parfaite harmonie avec l‘équipe projet d’ELIT. Ceci a
                      engendré une synchronisation parfaite quant aux activités,
                      aux délais et aux coûts inhérents à la gestion de ce
                      projet. Cette démarche à valeur ajoutée a été le facteur
                      prédominant pour la réussite de projet.
                    </p>
                    <div class="testimonial-name">
                      <h5>M.AGGOUN ALI</h5>
                      <p>Directeur de projet, ELIT</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="testimonial-nav-2">
                <div class="testimonial2-btn-prev">
                  <i class="fal fa-angle-left"></i>
                </div>
                <div class="testimonial2-btn-next">
                  <i class="fal fa-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-none d-lg-block">
            <div class="testimonial-right p-relative">
              <div class="test-i-img">
                <img
                  src="@/assets/img/testimonial/test-i-img.jpg "
                  alt="Adex Intégration témoignage"
                />
              </div>
              <div class="testimonial-right-img p-relative">
                <img
                  src="@/assets/img/testimonial/test-r-img.jpg "
                  alt="Adex Intégration témoignage"
                />
                <div class="testimonial-quote pos-2">
                  <i class="fal fa-quote-left"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- testimonial area end -->
  </main>
</template>
<script>
export default {
  name: "Services",
  components: {},
  mounted() {
    document.title = "ADEX Intégration - Services";
  },
};
</script>