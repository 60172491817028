<template>
  <main>
    <!-- page title area start  -->
    <section
      class="page-title-area"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/bg/sorry-bg.jpg') + ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-content text-center">
              <div class="page-title-heading">
                <h1>Page Introuvable</h1>
              </div>
              <nav class="grb-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Accueil</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page ">
                    404
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- page title area end -->
    <!-- 404 area start  -->
    <section class="area-404 pt-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="content-404 text-center mb-30">
              <h2>404</h2>
              <h4>Désolé, nous n'avons pas pu trouver cette page</h4>
              <p class="mb-40">
                La page que vous recherchez a été déplacée, supprimée, renommée
                ou n'a jamais existé.
              </p>

              <div class="go-home">
                <router-link :to="{ name: 'Home' }" class="grb-border-btn st-1"
                  >Retour à l'accueil</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 404 area end -->
  </main>
</template>
<script>
export default {
  name: "NotFoundComponent",
  mounted() {
    document.title = "ADEX Intégration - Page Introuvable";
  },
};
</script>