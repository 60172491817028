<template>
  <!-- header area start  -->
  <div>
    <header>
      <div class="header__main header-sticky header-main-3">
        <div class="h3-bar">
          <a class="side-toggle d-none d-xxl-block" href="javascript:void(0)"
            ><i class="fal fa-bars"></i
          ></a>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-xl-2 col-lg-3 col-8">
              <div class="logo">
                <router-link :to="{ name: 'Home' }" class="logo-text-white"
                  ><img
                    src="@/assets/img/logo/logo-svg.svg"
                    alt="Adex Intégration logo svg"
                    class="white-logo"
                /></router-link>
                <router-link :to="{ name: 'Home' }" class="logo-text-black"
                  ><img
                    src="@/assets/img/logo/logo-colors-svg.svg"
                    alt="Adex Intégration logo svg"
                    class="white-logo"
                /></router-link>
              </div>
            </div>
            <div class="col-xl-10 col-lg-9 col-4">
              <div class="header__menu-area f-right">
                <div class="main-menu">
                  <nav id="mobile-menu">
                    <ul>
                      <li>
                        <router-link :to="{ name: 'Home' }"
                          >Accueil</router-link
                        >
                      </li>
                      <li class="menu-item-has-children">
                        <router-link :to="{ name: 'About' }"
                          >À Propos</router-link
                        >
                        <ul class="sub-menu">
                          <li>
                            <router-link :to="{ name: 'About' }"
                              >Qui sommes-nous ?</router-link
                            >
                          </li>
                          <li>
                            <router-link :to="{ name: 'Portfolio' }"
                              >Portfolio</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li>
                        <router-link :to="{ name: 'Services' }"
                          >Services</router-link
                        >
                      </li>

                      <li>
                        <router-link :to="{ name: 'Career' }"
                          >Carrière</router-link
                        >
                      </li>
                      <li>
                        <router-link :to="{ name: 'Contact' }"
                          >Contact</router-link
                        >
                      </li>
                    </ul>
                  </nav>
                </div>
                <div class="header__search">
                  <a
                    class="
                      search-btn
                      nav-search
                      search-trigger
                      d-none d-sm-inline-block
                    "
                    href="#"
                    ><i class="fal fa-search"></i
                  ></a>
                  <a class="side-toggle d-lg-none" href="javascript:void(0)"
                    ><i class="fal fa-bars"></i
                  ></a>
                </div>

                <ul class="menu-cta-3 d-none d-xl-inline-block">
                  <li class="">
                    <div class="cta__icon d-none">
                      <span><i class="fas fa-phone-alt"></i></span>
                    </div>
                    <div class="cta__content">
                      <p>Téléphone</p>
                      <span
                        ><a href="tel:023279078">(+213) 23 27 90 78</a></span
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- header area end -->
    <!-- side toggle start  -->
    <div class="fix">
      <div class="side-info">
        <div class="side-info-content">
          <div class="offset-widget offset-logo mb-30 pb-20">
            <div class="row align-items-center">
              <div class="col-9">
                <router-link :to="{ name: 'Home' }"
                  ><img
                    src="@/assets/img/logo/logo-couleurs.png"
                    alt="Adex intégration Logo"
                /></router-link>
              </div>
              <div class="col-3 text-end">
                <button class="side-info-close">
                  <i class="fal fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mobile-menu d-lg-none"></div>

          <div class="offset-widget mb-40 d-none d-lg-block">
            <div class="info-widget">
              <h4 class="offset-title mb-20 d-none">À Propos</h4>
              <p class="mb-30">
                Nos 12 ans d’expertise aux coté des équipes métiers, nous
                permettent de traduire vos impératifs en solutions de services
                personnalisés avec engagement de résultat. Nous nous proposons
                d’être votre partenaire de confiance pour garantir la
                performance et la sécurisation de votre SI et assurer votre
                transition vers le cloud.
              </p>
              <router-link
                :to="{ name: 'Contact' }"
                class="c-btn btn-round-02 d-none"
                >Contact</router-link
              >
            </div>
          </div>

          <div class="side-map mt-20 mb-30 d-none d-lg-block">
            <div style="width: 100%">
              <iframe
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=cit%C3%A9%20ben%20haddadi%20es-said,%2016002%20dar%20ediaf,%20cheraga+(ADEX%20Technology)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>

            <div class="contact-infos mt-30 mb-30">
              <div class="contact-list mb-30">
                <h4>Contact</h4>
                <router-link :to="{ name: 'Contact' }">
                  <i class="fal fa-map-marker-alt"></i>
                  <span
                    >Villa N°09 bis, Cité Ben Haddadi Essaid, Dar Diaf 16002
                    Chéraga,Alger</span
                  >
                </router-link>
                <a href="tel:(213)23279078" class="">
                  <i class="fal fa-phone"></i>
                  <span>(+213) 23 27 90 78 / 80</span>
                </a>
                <a href="mailto:contact@adextechnology.dz" class="">
                  <i class="far fa-envelope"></i>
                  <span>contact@adextechnology.dz</span>
                </a>
              </div>
              <div class="grb__social footer-social offset-social">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/ADEX.Technology.dz"
                      target="_blank"
                      ><i class="fab fa-facebook-f"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/ADEXTechnology?lang=fr"
                      target="_blank"
                      ><i class="fab fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/adex-technology"
                      target="_blank"
                      ><i class="fab fa-linkedin" target="_blank"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="offcanvas-overlay"></div>
    <!-- side toggle end -->
    <!-- Fullscreen search -->
    <div class="search-wrap">
      <div class="search-inner">
        <i class="fal fa-times search-close" id="search-close"></i>
        <div class="search-cell">
          <form method="get">
            <div class="search-field-holder">
              <input
                type="search"
                class="main-search-input"
                placeholder="Recherche..."
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end fullscreen search -->
</template>
<script>
export default {
  name: "Header",
  data() {
    return {};
  },
};
</script>