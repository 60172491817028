<template>
  <div>
    <div class="row wow fadeInUp service-details-more">
      <div class="col-lg-4 col-md-6">
        <router-link :to="{ name: 'DataCenter' }">
          <div class="service-box-single mb-40">
            <div class="service-box-content st-1">
              <div class="service-box-content-icon st-1">
                <i class="fas fa-server"></i>
              </div>
              <div class="service-box-content-text">
                <h5>Data Center</h5>
                <p>
                  Nous proposons des solutions intégrées qui modernisent votre
                  infrastructure physique tout en optimisant les performances et
                  la disponibilité...
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 col-md-6">
        <router-link :to="{ name: 'CyberSecuritySolutions' }">
          <div class="service-box-single mb-40">
            <div class="service-box-content st-1">
              <div class="service-box-content-icon st-1">
                <i class="fas fa-shield-alt"></i>
              </div>
              <div class="service-box-content-text">
                <h5>Cyber Security Solutions</h5>
                <p>
                  Nous vous proposons des solutions simples, complètes et
                  pertinentes pour assurer la sécurité de tous vos supports
                  informatiques...
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 col-md-6">
        <router-link :to="{ name: 'SoftwareSolutions' }">
          <div class="service-box-single mb-40">
            <div class="service-box-content st-1">
              <div class="service-box-content-icon st-1">
                <i class="fas fa-code"></i>
              </div>
              <div class="service-box-content-text">
                <h5>Software Solutions</h5>
                <p>
                  Nous vous proposons des solutions software complètes
                  (Messagerie, video conference, ERP, GED, etc)...
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 col-md-6">
        <router-link :to="{ name: 'DataManagement' }">
          <div class="service-box-single mb-40">
            <div class="service-box-content st-1">
              <div class="service-box-content-icon st-1">
                <i class="flaticon-analytics"></i>
              </div>
              <div class="service-box-content-text">
                <h5>Data Management</h5>
                <p>
                  Les entreprises génèrent des centaines de milliers de données
                  par jour et ont besoin d’être supporté avec des solutions
                  adéquates. Pour cela, ADEX est votre partenaire de
                  confiance...
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 col-md-6">
        <router-link :to="{ name: 'ProfessionalServices' }">
          <div class="service-box-single mb-40">
            <div class="service-box-content st-1">
              <div class="service-box-content-icon st-1">
                <i class="flaticon-web-maintenance"></i>
              </div>
              <div class="service-box-content-text">
                <h5>Professional Services</h5>
                <p>
                  Nous mettons à votre disposition des services de
                  professionnels dans le domaine IT pour l'intégration de vos
                  solutions accompagné de support et maintenance...
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 col-md-6">
        <router-link :to="{ name: 'Consulting' }">
          <div class="service-box-single mb-40">
            <div class="service-box-content st-1">
              <div class="service-box-content-icon st-1">
                <i class="flaticon-consultation"></i>
              </div>
              <div class="service-box-content-text">
                <h5>Consulting</h5>
                <p>
                  Riche de notre expertise et expérience, nous avons développé
                  une offre de consulting qui couvre l'assistance de maitrise
                  d’ouvrage, l’accompagnent et le conseil...
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ServicesSection",
  components: {},
};
</script>
