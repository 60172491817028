<template>
  <form class="contact-form" action="#">
    <div class="row wow fadeInUp">
      <div class="col-md-6 mb-30">
        <input type="text" placeholder="Nom" />
      </div>
      <div class="col-md-6 mb-30">
        <input type="text" placeholder="Prénom" />
      </div>
      <div class="col-md-12 mb-30">
        <input type="text" placeholder="Email" />
      </div>

      <div class="col-md-12 mb-30">
        <input type="text" placeholder="Titre du poste" />
      </div>
      <div class="col-md-12 mb-30">
        <textarea name="message" placeholder="Lettre de motivation"></textarea>
      </div>
      <div class="col-md-6 text-center mb-4">
        <span class="file text-center">
          <input type="file" name="file" id="file" />
          <label for="file"
            ><i class="fas fa-upload"></i> Uploader votre CV</label
          >
        </span>
      </div>

      <div class="col-md-6 text-center">
        <button type="submit"><i class="fas fa-paper-plane"></i>Envoyer</button>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  name: "CareerForm",
  data() {
    return {};
  },
};
</script>