<template>
  <div class="swiper-container brand-active">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "
            ><img src="@/assets/img/brand/fortinet.png " alt="fortinet "
          /></a>
          <a href="# "
            ><img src="@/assets/img/brand/fortinet.png " alt=" fortinet"
          /></a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "
            ><img src="@/assets/img/brand/huawei.png " alt=" huawei"
          /></a>
          <a href="# "
            ><img src="@/assets/img/brand/huawei.png " alt=" huawei"
          /></a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "
            ><img src="@/assets/img/brand/cisco.png " alt="cisco "
          /></a>
          <a href="# "
            ><img src="@/assets/img/brand/cisco.png " alt="cisco "
          /></a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "><img src="@/assets/img/brand/ibm.png " alt="ibm " /></a>
          <a href="# "><img src="@/assets/img/brand/ibm.png " alt="ibm " /></a>
        </div>
      </div>
            <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "><img src="@/assets/img/brand/veeam.png " alt="veeam" /></a>
          <a href="# "><img src="@/assets/img/brand/veeam.png " alt="veeam" /></a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "
            ><img src="@/assets/img/brand/lenovo.png " alt="lenovo "
          /></a>
          <a href="# "
            ><img src="@/assets/img/brand/lenovo.png " alt="lenovo "
          /></a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "
            ><img src="@/assets/img/brand/microsoft.png " alt="microsoft "
          /></a>
          <a href="# "
            ><img src="@/assets/img/brand/microsoft.png " alt="microsoft "
          /></a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "
            ><img src="@/assets/img/brand/oracle.png " alt="oracle "
          /></a>
          <a href="# "
            ><img src="@/assets/img/brand/oracle.png " alt="oracle "
          /></a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "
            ><img src="@/assets/img/brand/redhat.png " alt="redhat "
          /></a>
          <a href="# "
            ><img src="@/assets/img/brand/redhat.png " alt="redhat "
          /></a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "
            ><img src="@/assets/img/brand/sophos.png " alt="sophos"
          /></a>
          <a href="# "
            ><img src="@/assets/img/brand/sophos.png " alt="sophos"
          /></a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "
            ><img src="@/assets/img/brand/vmware.png " alt="vmware"
          /></a>
          <a href="# "
            ><img src="@/assets/img/brand/vmware.png " alt="vmware"
          /></a>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="single-brand">
          <a href="# "
            ><img src="@/assets/img/brand/zimbra.png " alt="zimbra"
          /></a>
          <a href="# "
            ><img src="@/assets/img/brand/zimbra.png " alt="zimbra"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Partners",
  components: {},
};
</script>
