<template>
  <form class="contact-form" action="#">
    <div class="row wow fadeInUp">
      <div class="col-md-6 mb-30">
        <input type="text" placeholder="Nom" />
      </div>
      <div class="col-md-6 mb-30">
        <input type="text" placeholder="Prénom" />
      </div>
      <div class="col-md-6 mb-30">
        <input type="text" placeholder="Email" />
      </div>
      <div class="col-md-6 mb-30">
        <input type="text" placeholder="Téléphone" />
      </div>
      <div class="col-md-12 mb-30">
        <input type="text" placeholder="Objet" />
      </div>
      <div class="col-md-12 mb-30">
        <textarea name="message" placeholder="Message....."></textarea>
      </div>
      <div class="col-md-6">
        <button type="submit"><i class="fas fa-paper-plane"></i>Envoyer</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {};
  },
};
</script>