<template>
  <main>
    <!-- page title area start  -->
    <section
      class="page-title-area"
      :style="{
        'background-image':
          'url(' + DynamicImage(serviceObject.backgroundImage) + ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-content text-center">
              <div class="page-title-heading">
                <h1>{{ serviceObject.title }}</h1>
              </div>
              <nav class="grb-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Accueil</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Services' }"
                      >Services</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ serviceObject.title }}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- page title area end -->
    <!-- service details area start  -->
    <section class="service-details-area pt-150 pb-80">
      <div class="container">
        <div class="service-details-img wow fadeInUp">
          <div class="row">
            <div class="col-lg-9">
              <div class="service-details-single-img">
                <img
                  :src="DynamicImage(serviceObject.imageOne)"
                  alt="Adex Intégration services"
                />
              </div>
            </div>
            <div class="col-lg-3">
              <div class="row">
                <div class="col-lg-12 col-sm-6">
                  <div class="service-details-single-img">
                    <img
                      :src="DynamicImage(serviceObject.imageTwo)"
                      alt="Adex Intégration services"
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-sm-6">
                  <div class="service-details-single-img">
                    <img
                      :src="DynamicImage(serviceObject.imageThree)"
                      alt="Adex Intégration services"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="service-details-content wow fadeInUp">
          <div class="service-details-heading">
            <h2>{{ serviceObject.title }}</h2>
          </div>
          <p>
            {{ serviceObject.description }}
          </p>
          <div class="row">
            <div
              class="col-md-12 col-lg-4 col-sm-12"
              v-for="serviceItem in serviceObject.listServices"
              :key="serviceItem.title"
            >
              <h5 class="mb-15">{{ serviceItem.title }}</h5>
              <ul class="execute-list">
                <li v-for="item in serviceItem.items" :key="item">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <h4 class="mt-45 mb-25">Explorer plus de services</h4>
          <ServicesSection></ServicesSection>
        </div>
      </div>
    </section>
    <!-- service details area end -->
  </main>
</template>
<script>
import ServicesSection from "@/components/ServicesSection.vue";
export default {
  name: "CyberSecurityService",
  components: {
    ServicesSection,
  },
  data() {
    return {
      serviceObject: {
        backgroundImage: "bg/security-bg.jpg",
        title: "Cyber Security Solutions",
        imageOne: "service/service-security-1.jpg",
        imageTwo: "service/service-security-2.jpg",
        imageThree: "service/service-security-3.jpg",
        description:
          "Conscient que la cybersécurité des infrastructures, des applications et des réseaux représente un enjeu majeur pour les entreprises. Nous vous proposons des solutions simples, complètes et pertinentes pour assurer la sécurité de tous vos supports informatiques liant sécurité et business centrée sur la protection des données et la prévention.",
        listServices: [
          {
            title: "Perimetrical Security",
            items: [
              "Network Security (Firewall, SSL & VPN)",
              "Access Control",
              "Web Application Firewall",
              "Email Security",
              "Proxy",
            ],
          },
          {
            title: "Embedded Security",
            items: [
              "Crypto Analyses",
              "Software extraction",
              "Reverse Engineering",
              "IoT",
            ],
          },
          {
            title: "Data Security",
            items: [
              "Data Protection and Availability",
              "Load Balancer",
              "DLP (Data lost protection)",
            ],
          },
          {
            title: "Security Management (SOC, NOC)",
            items: ["Vulnerability Management", "SIEM", "SOC-NOC"],
          },
          {
            title: "End Point Security",
            items: ["Antivirus", "End point and device security"],
          },
        ],
      },
    };
  },
  methods: {
    DynamicImage(img) {
      return require(`../../assets/img/${img}`);
    },
  },
  mounted() {
    document.title = "ADEX Intégration - Cyber Security Solutions";
  },
};
</script>