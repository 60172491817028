<template>
  <main>
    <!-- page title area start  -->
    <section
      class="page-title-area"
      :style="{
        'background-image':
          'url(' + require('@/assets/img/bg/corporate-background.png') + ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-content text-center">
              <div class="page-title-heading">
                <h1>Portfolio</h1>
              </div>
              <nav class="grb-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Accueil</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Portfolio
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- page title area end -->

    <!-- Clients Télécommunication area start -->
    <section class="blog-area pt-120 blog-area-3 mb-5">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="section-title mb-55">
              <div class="border-left st-3">
                <p>Nos clients</p>
              </div>
              <h2>Télécommunication</h2>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="col-12">
            <div class="swiper-container brand-active">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img src="@/assets/img/brand/atm.png" alt="atm"
                    /></a>
                    <a href="#"
                      ><img src="@/assets/img/brand/atm.png" alt="atm"
                    /></a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img src="@/assets/img/brand/mobilis.png" alt="mobilis"
                    /></a>
                    <a href="#"
                      ><img src="@/assets/img/brand/mobilis.png" alt="mobilis"
                    /></a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img src="@/assets/img/brand/ooredoo.png" alt="ooredoo"
                    /></a>
                    <a href="#"
                      ><img src="@/assets/img/brand/ooredoo.png" alt="ooredoo"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blog-area pt-120 blog-area-3 mb-5">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="section-title mb-55">
              <div class="border-left st-3">
                <p>Nos clients</p>
              </div>
              <h2>Banques & Assurances</h2>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="col-12">
            <div class="swiper-container brand-active">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img
                        src="@/assets/img/brand/fransabank.png"
                        alt="fransabank"
                    /></a>
                    <a href="#"
                      ><img
                        src="@/assets/img/brand/fransabank.png"
                        alt="fransabank"
                    /></a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img src="@/assets/img/brand/agb.png" alt="agb"
                    /></a>
                    <a href="#"
                      ><img src="@/assets/img/brand/agb.png" alt="agb"
                    /></a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img src="@/assets/img/brand/bdl.png" alt="bdl"
                    /></a>
                    <a href="#"
                      ><img src="@/assets/img/brand/bdl.png" alt="bdl"
                    /></a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img
                        src="@/assets/img/brand/housingbank.png"
                        alt="housingbank"
                    /></a>
                    <a href="#"
                      ><img
                        src="@/assets/img/brand/housingbank.png"
                        alt="housingbank"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blog-area pt-120 blog-area-3 mb-5">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="section-title mb-55">
              <div class="border-left st-3">
                <p>Nos clients</p>
              </div>
              <h2>Institutions Publiques</h2>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="col-12">
            <div class="swiper-container brand-active">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img src="@/assets/img/brand/ppg.png" alt="ppg"
                    /></a>
                    <a href="#"
                      ><img src="@/assets/img/brand/ppg.png" alt="ppg"
                    /></a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img src="@/assets/img/brand/ehu.png" alt="ehu"
                    /></a>
                    <a href="#"
                      ><img src="@/assets/img/brand/ehu.png" alt="ehu"
                    /></a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img
                        src="@/assets/img/brand/sidi-belabbes.png"
                        alt="sidi-belabbes"
                    /></a>
                    <a href="#"
                      ><img
                        src="@/assets/img/brand/sidi-belabbes.png"
                        alt="sidi-belabbes"
                    /></a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img src="@/assets/img/brand/eshra.png" alt="eshra"
                    /></a>
                    <a href="#"
                      ><img src="@/assets/img/brand/eshra.png" alt="eshra"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blog-area pt-120 blog-area-3 mb-5">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="section-title mb-55">
              <div class="border-left st-3">
                <p>Nos clients</p>
              </div>
              <h2>Pharmaceutique</h2>
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="col-12">
            <div class="swiper-container brand-active">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img
                        src="@/assets/img/brand/astrazeneca.png"
                        alt="astrazeneca"
                    /></a>
                    <a href="#"
                      ><img
                        src="@/assets/img/brand/astrazeneca.png"
                        alt="astrazeneca"
                    /></a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="single-brand">
                    <a href="#"
                      ><img
                        src="@/assets/img/brand/pharmalliance.png"
                        alt="pharmalliance"
                    /></a>
                    <a href="#"
                      ><img
                        src="@/assets/img/brand/pharmalliance.png"
                        alt="pharmalliance"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Clients area end -->
  </main>
</template>
<script>
export default {
  name: "Portfolio",
  components: {},
  mounted() {
    document.title = "ADEX Intégration - Portfolio";
  },
};
</script>