<template>
  <main>
    <!-- page title area start  -->
    <section
      class="page-title-area"
      :style="{
        'background-image':
          'url(' + DynamicImage(serviceObject.backgroundImage) + ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-content text-center">
              <div class="page-title-heading">
                <h1>{{ serviceObject.title }}</h1>
              </div>
              <nav class="grb-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Home' }">Accueil</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Services' }"
                      >Services</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ serviceObject.title }}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- page title area end -->
    <!-- service details area start  -->
    <section class="service-details-area pt-150 pb-80">
      <div class="container">
        <div class="service-details-img wow fadeInUp">
          <div class="row">
            <div class="col-lg-9">
              <div class="service-details-single-img">
                <img
                  :src="DynamicImage(serviceObject.imageOne)"
                  alt="Adex Intégration services"
                />
              </div>
            </div>
            <div class="col-lg-3">
              <div class="row">
                <div class="col-lg-12 col-sm-6">
                  <div class="service-details-single-img">
                    <img
                      :src="DynamicImage(serviceObject.imageTwo)"
                      alt="Adex Intégration services"
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-sm-6">
                  <div class="service-details-single-img">
                    <img
                      :src="DynamicImage(serviceObject.imageThree)"
                      alt="Adex Intégration services"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="service-details-content wow fadeInUp">
          <div class="service-details-heading">
            <h2>{{ serviceObject.title }}</h2>
          </div>
          <p>
            {{ serviceObject.description }}
          </p>
          <div class="row">
            <div
              class="col-md-12 col-lg-4 col-sm-12"
              v-for="serviceItem in serviceObject.listServices"
              :key="serviceItem.title"
            >
              <h5 class="mb-15">{{ serviceItem.title }}</h5>
              <ul class="execute-list">
                <li v-for="item in serviceItem.items" :key="item">
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
          <h4 class="mt-45 mb-25">Explorer plus de services</h4>
          <ServicesSection></ServicesSection>
        </div>
      </div>
    </section>
    <!-- service details area end -->
  </main>
</template>
<script>
import ServicesSection from "@/components/ServicesSection.vue";
export default {
  name: "ConsultingService",
  components: {
    ServicesSection,
  },
  data() {
    return {
      serviceObject: {
        backgroundImage: "bg/page-title-bg.jpg",
        title: "Consulting",
        imageOne: "service/service-consulting-1.jpg",
        imageTwo: "service/service-consulting-2.jpg",
        imageThree: "service/service-consulting-3.jpg",
        description:
          "Parce que votre business est critique et que la satisfaction de vos clients fait partie de vos prérogatives, votre entreprise ne peut se permettre des interruptions de service, nous vous accompagnons dans la mise en place d’un plan de reprise d’activité et de la gestion des risques.",
        listServices: [
          {
            title: "Conseil",
            items: [
              "Conception des solutions",
              "Rédaction des cahiers des charges",
              "Mise en place de POC",
              "Accompagnement & formations",
              "Plan directeur Numérique",
            ],
          },
          {
            title: "Business Continuity & Risk Management",
            items: [
              "Accompagnement à la continuité de service",
              "Gestion des risques",
              "Process",
            ],
          },
          {
            title: "MOA",
            items: ["Assistance a Maîtrise d’ouvrage"],
          },
        ],
      },
    };
  },
  methods: {
    DynamicImage(img) {
      return require(`../../assets/img/${img}`);
    },
  },
  mounted() {
    document.title = "ADEX Intégration - Consulting";
  },
};
</script>